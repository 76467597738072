<template>
  <div>
    <div class="login">
      <div>
        <el-form ref="form" :rules="rules" :model="form" label-width="80px" label-position="left">
          <div class="title-container">
            <div class="title">
              槃古云
            </div>
            <!--        <div class="subTitle">-->
            <!--          多多店铺版-->
            <!--        </div>-->
          </div>
          <el-form-item label="手机号" prop="phone">
            <el-input v-model="form.phone"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input v-model="form.password" type="password"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit" :loading="isLoginLoading">登录</el-button>
          </el-form-item>
        </el-form>

      </div>
    </div>
    <div class="footer">
      <div class="company" style="padding:5px 0;color:#fff;">新昌县槃古环保科技有限公司</div>
      <div style="padding:5px 0;">
        <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33062402000829"
           style="display:inline-block;text-decoration:none;height:20px;line-height:20px;color:#fff;">
          <img src="../../assets/beian.png" style="float:left;"/>
          <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px;">
          浙公网安备 33062402000829号</p>
        </a>
      </div>
      <div style="padding:5px 0;color:#fff;">
        <a class="space" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;color:#fff;" target="_blank" rel="noreferrer" href="https://beian.miit.gov.cn/"
           data-track-category="Footer" data-track-name="底部"
           data-track-value="浙ICP备2021002378号-2">浙ICP备2021002378号-2</a>
      </div>
    </div>
  </div>
</template>

<script>
import {validMobile} from '@/utils/validate'
import {login} from '@/services/user'

export default {
  name: 'LoginIndex',
  data() {
    // 自定义校验函数
    const validatePhone = function (rule, value, callback) {
      // 校验value
      // if (validMobile(value)) {
      //   // 如果通过 直接执行callback
      //   callback()
      // } else {
      //   callback(new Error('手机号格式不正确'))
      // }
      validMobile(value) ? callback() : callback(new Error('手机号格式不正确'))
    }
    return {
      // 存储表单数据的对象
      form: {
        // phone: '15321919577',
        // password: '111111'
        phone: '',
        password: ''
      },
      // 用于设置表单校验规则
      rules: {
        phone: [
          {
            required: true,
            trigger: 'blur',
            message: '手机号不能为空'
          },
          {
            validator: validatePhone,
            trigger: 'blur'
          }
        ],
        password: [
          {
            required: true,
            trigger: 'blur',
            message: '密码不能为空'
          },
          {
            min: 6,
            max: 16,
            message: '密码的长度在6-16位之间 ',
            trigger: 'blur'
          }
        ]
      },
      // 用于保存加载状态
      isLoginLoading: false
    }
  },
  methods: {
    // 登录按钮提交
    async onSubmit() {
      try {
        // 设置校验
        await this.$refs.form.validate()
        // 发送请求
        this.isLoginLoading = true
        const {data} = await login(this.form)
        // 响应数据处理
        if (200 === data.code) {
          // 登录成功 将用户信息存储到Vuex
          this.$store.commit('setUser', data.data)
          // 获取菜单
          this.$store.commit('setRouteCnName', this.$route.query.redirect || '/')
          await this.$store.dispatch('setMenuList')
          // 根据可能存在的redirect 数据进行跳转设置
          await this.$router.push(this.$route.query.redirect || '/')
        } else {
          this.$message.error(data.message)
        }
      } catch (err) {
        // 设置校验失败后的功能提示
        console.log(err)
      }
      this.isLoginLoading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.login {
  //background-color: #40586F;
  background-color: #409EFF;
  height: 90vh;
  display: flex;
  //设置内部元素垂直水平居中
  justify-content: center;
  align-items: center;

  .title-container {
    margin-bottom: 20px;
    position: relative;

    .title {
      margin-bottom: 10px;
      font-size: 20px;
      color: #40586F;
      text-align: center;
      font-weight: bold;
    }

    .subTitle {
      font-size: 12px;
      color: #40586F;
      text-align: center;
      font-weight: bold;
    }
  }

  .el-form {
    background-color: #fff;
    padding: 20px;
    width: 300px;
    border-radius: 10px;

    .el-button {
      width: 100%;
    }
  }
}

.footer {
  //background-color: #40586F;
  background-color: #409EFF;
  height: 10vh;
  display: flex;
  //设置内部元素垂直水平居中
  justify-content: center;
  //align-items: center;
  text-color: #40586F;
  div {
    margin: 10px;
  }
}
</style>
